import React, { FC } from 'react';
import cx from 'classnames';

import FooterSocialMenu from './FooterSocialMenu';
import LinkSecondary from '../links/LinkSecondary';

import checkopsLogo from '../../images/checkops-logo.svg';
import netlifyLogo from '../../images/netlify.svg';

import './c-footer.scss';
import LinkLightAsAnchor from '../links/LinkLight/LinkLightAsAnchor';
import LinkSecondaryAsAnchor from '../links/LinkSecondary/LinkSecondaryAsAnchor';

interface Props {
  className?: string;
}

const Footer: FC<Props> = ({ className }) => (
  <footer className={cx('c-footer', className)}>
    <div className="container-layout">
      <div className="row">
        <div className="col col--sm-6 u-padding-ends">
          <LinkLightAsAnchor
            className="c-footer__brand-logo-link"
            href="https://www.checkops.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img className="c-footer__brand-logo" src={checkopsLogo} alt="CheckOps" width="130" height="600" />
          </LinkLightAsAnchor>

          <div className="u-font-weight-500">
            Checklists for Internet
          </div>
          <nav className="c-footer__social-nav" aria-labelledby="social-menu-label">
            <h2 className="u-visually-hidden" id="social-menu-label">
              CheckOps Social Menu
            </h2>
            <FooterSocialMenu />
          </nav>
        </div>
        <div className="col col--sm-6 col--md-3 u-padding-ends">
          <nav aria-labelledby="checkops-menu-label">
            <h6 className="c-footer__title" id="checkops-menu-label">
              CheckOps
            </h6>
            <ul className="c-footer__menu">
              <li className="u-margin-top-2xsmall">
                <LinkSecondaryAsAnchor
                  href="https://labs.checkops.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Run Free Test
                </LinkSecondaryAsAnchor>
              </li>
              <li className="u-margin-top-2xsmall">
                <LinkSecondaryAsAnchor
                  href="https://www.blog.checkops.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Read Our Blogs 
                </LinkSecondaryAsAnchor>
              </li>
              <li className="u-margin-top-2xsmall">
                <LinkSecondaryAsAnchor
                  href="https://labs.checkops.com/contact"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Contact Us
                </LinkSecondaryAsAnchor>
              </li>
            </ul>
          </nav>
        </div>
        <div className="col col--sm-6 col--md-3 u-padding-ends">
          <nav aria-labelledby="checklist-menu-label">
            <h6 className="c-footer__title" id="checklist-menu-label">
              Checklist
            </h6>
            <ul className="c-footer__menu">
              <li className="u-margin-top-2xsmall">
                <LinkSecondary to="/about">About</LinkSecondary>
              </li>
              <li className="u-margin-top-2xsmall">
                <LinkSecondaryAsAnchor
                  href={`${process.env.GATSBY_GITHUB_LINK}/blob/master/CODE_OF_CONDUCT.md`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Code of Conduct
                </LinkSecondaryAsAnchor>
              </li>
              <li className="u-margin-top-2xsmall">
                <LinkSecondaryAsAnchor
                  href={`${process.env.GATSBY_GITHUB_LINK}/blob/master/CONTRIBUTING.md`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Contributing
                </LinkSecondaryAsAnchor>
              </li>            </ul>
          </nav>
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;
