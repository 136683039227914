import { MarkdownRemarkFrontmatter, Maybe } from '../../graphql-types';
import { Theme } from './theme';

export type Category =
  | 'front-end'
  | 'back-end'
  | 'security'
  | 'devops'
  | 'design'
  | 'project-management'
  | 'testing'
  | 'marketing'
  | 'general'
  | 'default';

export const categoryTheme: { [key in Category]: Theme } = {
  default: 'default',
  'front-end': 'dodger-blue',
  'back-end': 'electric-violet',
  mobile: 'purple-heart',
  devops: 'fuzzy-wuzzy-brown',
  'security': 'rose',
  testing: 'clementine',
  marketing: 'gossamer',
  general: 'blue',
};

export const getDescription = (title: string): string => {
  switch (title) {
    case 'Design':
      return 'These checklists ensure that we have standardized both visual design and user experience design principles.';

    case 'Front-End':
      return 'Checklists mainly for front-end (web) developers,testers or website owners';

    case 'Back-End':
      return 'Server related, data-driven and API based checklists that help making our products of hard work stay steady.';

    case 'Mobile':
      return 'Connecting people by covering all the processes from mobile development to roll-out the application.';

    case 'DevOps':
      return 'We love to automate things and have also checklists for that.';

    case 'Testing':
      return 'These are checklists for testing your project.';

    case 'Security':
      return 'Checklist for all things related to security';

    case 'Marketing':
      return 'These are checklists for marketing and engagement activities';

    case 'General':
      return 'There are some checklists which are hard to categorize. Let’s just call them generals.';

    default:
      return 'A checklist category!';
  }
};

export const getMostUsedTags = (
  nodes?: Array<{ frontmatter?: Maybe<Pick<MarkdownRemarkFrontmatter, 'tags'>> }>,
): string[] => {
  const items: Record<string, number> = {};

  (nodes || []).forEach(node => {
    if (!node.frontmatter) {
      return;
    }

    (node.frontmatter.tags || []).forEach(tag => {
      const tagName = tag || '';
      if (items[tagName]) {
        items[tagName] += 1;
        return;
      }

      items[tagName] = 1;
    });
  });

  return Object.entries(items)
    .sort((i1, i2) => i2[1] - i1[1])
    .map(i => i[0])
    .slice(0, 4);
};
